//App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard'; // Your dashboard component
import InputWindow from './InputWindow'; // Assuming you want to include this as well

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<InputWindow />} /> {/* Use InputWindow for the root path if it's meant for authentication */}
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
