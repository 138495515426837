//Dashboard.js
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import logoImage from './logo.png';
import moment from 'moment';
import './App.css';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

registerLocale('ko', ko);

const Dashboard = ({ accessToken}) => {
  const ADMIN_ROOT_LF_URL = 'https://zk7ogbd45ojct3nzbi7nzsb52e0bqmku.lambda-url.ap-northeast-2.on.aws/api/admin'
  const DAILY_ACCESS_URL = `${ADMIN_ROOT_LF_URL}/daily_access`
  const USER_INFO_URL = `${ADMIN_ROOT_LF_URL}/user_info`
  const BUTTONS_CLICK_COUNT = `${ADMIN_ROOT_LF_URL}/button`
  const FEEDBACK_STATISTICS = `${ADMIN_ROOT_LF_URL}/feedback_Stats`
  const navigate = useNavigate();
  const [dailyAccessArray, setDailyAccessArray] = useState([]);
  const [transformedFeedbackData, setTransformedFeedbackData] = useState([]);
  const [btnClickData, setbtnClickData] = useState([]);
  const [userInfoData, setUserInfoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offsetDate, setOffsetDate] = useState(null);
  const maxUsers = Math.max(...btnClickData.map(data => data.value));
  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#413ea0']; // Example colors
  const [startDate, setStartDate] = useState(moment().subtract(14, 'days').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();
  const btnLabels = [
    "고객기본정보", "연간납입한도", "운용상품신청정보",
    "디폴트옵션", "수익률통지서비스"
  ];
  const feedbackLabels = [
    "참신함","재미있음","도움이 됨","신기함",
  "흥미로움","유익함", "실감남","알기쉬움","생동감있음",
  "생소함","지루함","촌스러움","부자연스러움","핸드폰 들고 있기 힘듦"
];
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="custom-datepicker-input" onClick={onClick} ref={ref}>
      {value} <i className="fas fa-calendar-alt"></i>
    </button>
  ));
  const handleStartDateChange = (date) => setStartDate(date);
  const handleEndDateChange = (date) => setEndDate(date);
  accessToken = location.state.accessToken; // 테스트용
  
  const getDailyAccess = async (accessToken) => {
    try {
      const response = await axios.get(DAILY_ACCESS_URL, {
        params: {
          fromDate: startDate,
          toDate: endDate,
        },
        headers: {
          authorization: accessToken
        }
      });
      if (response.status === 200) {
        console.log(response.data)
        return response.data;
      } else {
        console.log("Error: " + response.data);
        return []; // or any default value
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Session expired. Redirecting to login.");
        // Redirect the user to the InputWindow.js for re-authentication
        navigate('/');
      } else {
        // Handle other types of errors
        console.error("An error occurred:", error);
      }
      return []; // or any default value
    }
  };
  const getBtnCount = async (accessToken) => {
    try {
      const response = await axios.get(BUTTONS_CLICK_COUNT, {
        headers: { authorization: accessToken }
      });
      if (response.status === 200) {
        console.log(response.data)
        return response.data;
      } else {
        console.error("Error fetching button click count data:", response.data);
        return []; // Return default value
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Session expired. Redirecting to login.");
        // Redirect the user to the InputWindow.js for re-authentication
        navigate('/');
      } else {
        // Handle other types of errors
        console.error("An error occurred:", error);
      }
      return []; // Return default value
    }
  };
  const getFeedbackStats = async (accessToken) => {
    try {
      const response = await axios.get(FEEDBACK_STATISTICS, {
        headers: {
          authorization: accessToken
        }
      });
      if (response.status === 200) {
        const feedbackStats = response.data;
        console.log(feedbackStats);
        return response.data;
      } else {
        console.error("Error fetching button click count data:", response.data);
        return []; // Return default value
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Session expired. Redirecting to login.");
        // Redirect the user to the InputWindow.js for re-authentication
        navigate('/');
      } else {
        // Handle other types of errors
        console.error("An error occurred:", error);
      }
      return []; // Return default value
    }
  };
  const getUserInfo = async (offsetDate) => {
    try {
      const response = await axios.get(USER_INFO_URL, {
        params: {
          offsetDate: offsetDate,
        },
        headers: {
          authorization: accessToken  
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        console.log(response.data.metadata.offsetDate);
        setOffsetDate(response.data.metadata.offsetDate);
        
        for(let i = 0; i < response.data.userInfo.length; i++){
          setUserInfoData(oldArray => [...oldArray, response.data.userInfo[i]] )  ;
        }

        console.log('>>>>>>>', userInfoData);
        return response.data.userInfo;

      } else {
        console.error("Error fetching user info:", response.data);
        return null;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Session expired. Redirecting to login.");
        // Redirect the user to the InputWindow.js for re-authentication
        navigate('/');
      } else {
        // Handle other types of errors
        console.error("An error occurred:", error);
      }

      return null;
    } 
  };

  // LoadMoreButton Click
  const loadMore = async () => {
    setLoading(true);
    const moreUserInfo = await getUserInfo(offsetDate);
    setLoading(false);
    if (moreUserInfo !== null) {
      setUserInfoData(prevUserInfo => [...prevUserInfo, ...moreUserInfo]);
    }
  };
  
  useEffect(() => {
    const fetchDailyData = async () => {
      try {
        // Fetch daily access data
        const dailyData = await getDailyAccess(accessToken);
        const dailyAccessArray = Object.values(dailyData);
        setDailyAccessArray(dailyAccessArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDailyData();
  }, [startDate, endDate, accessToken]);

  useEffect(() => {
    const fetchBtnCount = async () => {
      try {
        // Fetch button click count data
        const cumulativeUsersData = await getBtnCount(accessToken);
        const cumulativeUsersDataArray = Object.values(cumulativeUsersData);
        const newData = cumulativeUsersDataArray.map((value, index) => ({
          label: btnLabels[index],
          value: value
        }));
        console.log(newData);
        setbtnClickData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchBtnCount();
  }, [accessToken]);

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const feedbackStats = await getFeedbackStats(accessToken);
        console.log("Feedback Stats:", feedbackStats);
  
        // Transform the feedback data
        const transformedFeedbackData = feedbackStats.map((item, index) => {
          const value = parseInt(item.N, 10); // Assuming item.N is the count needing to be parsed
          const label = feedbackLabels[index]; // Assuming this maps correctly to your data
          return { label, value };
        });
  
        // Now sort the transformed data in descending order by value
        const sortedFeedbackData = transformedFeedbackData.sort((a, b) => b.value - a.value);
  
        console.log("Sorted and Transformed Data:", sortedFeedbackData);
        setTransformedFeedbackData(sortedFeedbackData); // Set the state with sorted data
      } catch (error) {
        console.error("Error fetching feedback statistics:", error);
      }
    };
  
    fetchFeedbackData();
  }, [accessToken]);

  useEffect( () => {
    getUserInfo();
  }, [accessToken]);
  ;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.logoContainer}>
          <img src={logoImage} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>AR 서식 대시보드</h1>
        </div>
        <div style={styles.emptyContainer}>
          {/* This space is intentionally left blank */}
        </div>
      </div>
      <div style={styles.gridContainer}>
        <div style={styles.item}>
          {/* Use a flex container to align title and date pickers side by side */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <h3>일별 접속자 수</h3>
            <div style={{ display: 'flex', gap: '20px' }}>
              <div style={styles.dateLabel}>
                <span>조회시작일</span>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="yyyy-MM-dd"
                  locale="ko"
                  customInput={<CustomInput />}
                  maxDate={new Date(endDate.getTime() - 86400000)}
                />
              </div>
              <div style={styles.dateLabel}>
                <span>조회종료일</span>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="yyyy-MM-dd"
                  locale="ko"
                  customInput={<CustomInput />}
                  minDate={new Date(startDate.getTime() + 86400000)}
                  maxDate={new Date(startDate.getTime() + 30 * 86400000)}
                />
              </div>
            </div>
          </div>
          <div style={styles.centeredContent}>
  <LineChart width={500} height={150} data={dailyAccessArray}>
    <Line type="linear" dataKey="value" stroke="#8884d8" />
    <CartesianGrid stroke="#ccc" />
    <XAxis dataKey="label" />
    <YAxis />
    <Tooltip />
  </LineChart>
</div>
        </div>
        {/* Start of the next item */}
        <div style={styles.item}>
      <div style={styles.itemHeader}>
        <h3 style={styles.titleLeft}>설명별 누적 사용자수</h3>
      </div>
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '40%', textAlign: 'center'}}>설명</th>
            <th style={{ width: '60%', textAlign: 'center' }}>사용자수</th>
          </tr>
        </thead>
        <tbody>
          {btnClickData.map((data, index) => (
            <tr key={index}>
              <td style={{ alignItems: 'center', textAlign: 'center'}}> {data.label}</td>
              <td style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  {/* Proportional Bar */}
                  <div style={{
                    backgroundColor: barColors[index % barColors.length],
                    height: '20px',
                    width: `${(data.value / maxUsers) * 100}%`,
                    marginRight: '10px',
                    alignItems: 'right'
                  }}></div>
                  {/* Numeric Text */}
                  <span style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                    {data.value}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        
<div style={styles.item} className="connection-history-container">
  <div style={styles.itemTitle}>
    <h3>접속 이력</h3>
    {/* Excel Download Button */}
    </div>
  {/* </div> */}
  <div style={styles.centeredContent}>
  <Table striped bordered hover>
  <thead>
    <tr>
      <th style={{ padding: '0 10px' }}>No</th>
      <th style={{ padding: '0 10px' }}>접속일시</th>
      <th style={{ padding: '0 10px' }}>점수</th>
      <th style={{ padding: '0 10px' }}>Q1</th>
      <th style={{ padding: '0 10px' }}>Q2</th>
      <th style={{ padding: '0 10px' }}>Q3</th>
      <th style={{ padding: '0 10px' }}>Q4</th>
      <th style={{ padding: '0 10px' }}>Q5</th>
      <th style={{ padding: '0 10px' }}>종료</th>
    </tr>
  </thead>
  <tbody>
    {userInfoData.map((item, index) => (
      <tr key={index}>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{index + 1}</td>
        <td style={{ textAlign: 'center' }}>
          {(() => {
            const date = new Date(item.date);
            const koreanTime = new Date(date.getTime() + (date.getTimezoneOffset() * 60000) + (9 * 3600000)); // Adjust for Korean time
            const year = koreanTime.getFullYear();
            const month = koreanTime.getMonth() + 1; // getMonth() is zero-based
            const day = koreanTime.getDate();
            const hours = koreanTime.getHours();
            const minutes = koreanTime.getMinutes();

            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          })()}
        </td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.rating}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_01 ? 'O' : 'X'}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_02 ? 'O' : 'X'}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_03 ? 'O' : 'X'}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_04 ? 'O' : 'X'}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_05 ? 'O' : 'X'}</td>
        <td style={{ textAlign: 'center', padding: '0 10px' }}>{item.ex_06 ? 'O' : 'X'}</td>
      </tr>
    ))}
  </tbody>
</Table>
</div>
<div style={styles.centeredContent}>
{loading && <p>Loading...</p>}
      <button onClick={loadMore} disabled={loading}>더 불러오기</button>
</div>
        </div>
        <div style={styles.item}>
      <div style={styles.itemTitle}>
        <h3>사용자 피드백</h3>
      </div>
      <div style={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
  {/* Left Table */}
  <div style={{ flex: 1, padding: '0 10px' }}>
    <Table striped bordered hover style={{ margin: 'auto', textAlign: 'center' }}>
    <thead>
          <tr>
            <th style={{ width: '50px' }}>순위</th>
            <th style={{ width: '150px' }}>피드백</th>
            <th style={{ width: '80px' }}>횟수</th>
          </tr>
        </thead>
        <tbody>
          {transformedFeedbackData.slice(0, Math.ceil(transformedFeedbackData.length / 2)).map((feedback, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ paddingLeft: '25px' }}>{feedback.label}</td>
              <td>{feedback.value}</td>
            </tr>
          ))}
        </tbody>
    </Table>
  </div>
  {/* Divider Line */}
  <div style={{ width: '2px', backgroundColor: '#000', height: '200px' }}></div>
  {/* Right Table */}
  <div style={{ flex: 1, padding: '0 10px' }}>
    <Table striped bordered hover style={{ margin: 'auto', textAlign: 'center' }}>
    <thead>
          <tr>
            <th style={{ width: '50px' }}>순위</th>
            <th style={{ width: '150px' }}>피드백</th>
            <th style={{ width: '80px' }}>횟수</th>
          </tr>
        </thead>
        <tbody>
          {transformedFeedbackData.slice(Math.ceil(transformedFeedbackData.length / 2)).map((feedback, index) => (
            <tr key={index}>
              <td>{index + Math.ceil(transformedFeedbackData.length / 2) + 1}</td>
              <td style={{ paddingLeft: '25px' }}>{feedback.label}</td>
              <td>{feedback.value}</td>
            </tr>
          ))}
        </tbody>
    </Table>
  </div>
</div>
      </div>
    </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '0px',
    backgroundColor: 'rgb(240, 241, 246)',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between', // This will distribute the space between the items
    alignItems: 'center',
    padding: '0 20px', // Padding on the sides
  },
  logoContainer: {
    width: '33.333%', // Each container takes up a third of the header's width
    display: 'flex',
    justifyContent: 'flex-start', // Aligns the logo to the left
  },
  titleContainer: {
    width: '33.333%',
    display: 'flex',
    justifyContent: 'center', // Centers the title
  },
  emptyContainer: {
    width: '33.333%', // The right container is left empty and takes up a third of the width
  },
  logo: {
    height: '50px', // Adjust the logo size as needed
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Ensure 2x2 layout with two columns
    gridGap: '20px', // Adjust the gap as needed
    width: '90%', // Adjust based on your layout needs
    paddingBottom: '20px', // Add some padding at the bottom to ensure space for all content
  },
   itemTitle: {
    width: '100%',
    display: 'flex', // Make the title container a flex container
    justifyContent: 'space-between', // Space between child elements
    alignItems: 'center', // Vertically align items in the middle
    marginBottom: '10px', // Space below the title container
  },
  titleLeft: {
    flex: 1, // Take up 1/3 of the space
    display: 'flex',
    justifyContent: 'flex-start', // Align title to the left
  },
  titleCenter: {
    flex: 1, // Take up 1/3 of the space, left intentionally empty for now
  },
  titleRight: {
    flex: 1, // Take up 1/3 of the space
    display: 'flex',
    justifyContent: 'flex-end', // Align date filters to the right
  },
  dateRangeContainer: {
    display: 'flex',
    justifyContent: 'flex-end', // Ensure the date range is aligned to the right
    alignItems: 'center',
    gap: '20px',
    width: '100%', // Ensure it takes up the full width of its container
  },
  // Adjustments for dateLabel and dateInput as needed
  dateLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
  },
  // Adjust the container of the itemTitle if necessary
  item: {
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align children to the start (left) of the container
    justifyContent: 'flex-start',
    minHeight: '0px',
    maxHeight: '58vh',
    overflow: 'auto',
    overflowY: 'auto',
    paddingTop: '0', // Adjust if the item's top padding is affecting the title position
    marginTop: '0', // Adjust if necessary
  },
  // Add a new style for centering content
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%', // Ensures it spans the full width of its container
  },
  
  // Include or adjust styles for other elements as necessary
  dateInput: {
    margin: '5px 10px', // Adjust as needed for visual clarity
  },
};

export default Dashboard;