import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import logoImage from './logo.png'; // Import the JPG image
import axios from 'axios';

const ADMIN_ROOT_LF_URL = 'https://zk7ogbd45ojct3nzbi7nzsb52e0bqmku.lambda-url.ap-northeast-2.on.aws/api/admin/'
const LOGIN_URL = `${ADMIN_ROOT_LF_URL}login/`

let accessToken = null; 

// {
//   "version": "1.0",
//   "rawPath": "/api/admin/login/",
//   "queryStringParameters": {
//     "pin": "5908034"
//   }
// }

// {
//   "statusCode": 400,
//   "body": {
//     "Authorization": "cff9d4e0-4c79-4a31-80ab-e5dd41377b10",
//     "expire_in": 600
//   }
// }

const getLogin = async (pin) => {
  let value = false;
  
  await axios.get(LOGIN_URL, {
    params: {
      pin: pin,      
    },
  }).then((response) => {
    console.log(response)
    if(response.status == 200){
      console.log(response.data)
      accessToken = response.data.authorization;
      console.log(accessToken);
    }
    value= true;
  }).catch((error) => {
    console.log(error)
    value = false
  })

  return value;
};

const InputWindow = () => {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to handle input change
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // console.log(e.target[0].value);

    const value = await getLogin(inputValue);   
    console.log(value);

    if (value) { // Assuming 'kb123' is your authentication PIN
      navigate('/dashboard', { state:{ accessToken : accessToken} }); // Navigate to the dashboard on successful authentication
    } else {
      alert('Incorrect PIN code. Please try again.');
      setInputValue(''); // Optionally clear input field after unsuccessful submission
    }
  };

  return (
    <div style={styles.container}>
      {/* Image placed in the top left corner */}
      <img src={logoImage} alt="Logo" style={styles.logo} />
      <form onSubmit={handleSubmit} style={styles.inputContainer}> {/* Ensure you wrap inputs in a form for submit handling */}
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          style={styles.input}
          placeholder="PIN CODE 입력"
        />
        <button type="submit" style={styles.button}>확인</button>
      </form>
    </div>
  );
};

// Styles remain unchanged
const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height of the viewport
  },
  logo: {
    position: 'absolute',
    top: '20px', // Adjust the top position as needed
    left: '20px', // Adjust the left position as needed
    width: '200px', // Adjust the width of the image as needed
    height: 'auto', // Maintain aspect ratio
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    padding: '15px',
    borderRadius: '8px',
    marginRight: '10px', // Space between input and button
    fontSize: '18px', // Increased font size
    border: '2px solid black', // Set all borders to black
    outline: 'none', // Remove focus outline
  },
  button: {
    backgroundColor: '#FFBC00', // R255 G188 B0 color for button
    color: 'white', // White text color for button
    padding: '15px 30px', // Increased padding
    borderRadius: '8px',
    fontSize: '18px', // Increased font size
    cursor: 'pointer',
    border: 'none', // Removed border
  },
};

export default InputWindow;